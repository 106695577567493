import React from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import { DataGrid } from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

// import { useNavigate } from "react-router-dom";

const CabinetCustomerList = () => {
	// const navigate = useNavigate();

	const theme = useTheme(); // grab  theme from material ui context, set in layout.js
	const colors = tokens(theme.palette.mode);

	const mockData = [
		{
			id: "Juma Odinga",
			amount_paid: 5000,
			status: "complete",
			battery_id: "B12345",
			battery_od: "B3423",
		},
		{
			id: "Wambui Njeri",
			amount_paid: 3500,
			status: "incomplete",
			battery_id: "B67890",
			battery_od: "B3423",
		},
		{
			id: "Kiprotich Kipketer",
			amount_paid: 7000,
			status: "complete",
			battery_id: "B11223",
			battery_od: "B3423",
		},
		{
			id: "Grace Achieng",
			amount_paid: 4200,
			status: "complete",
			battery_id: "B33445",
			battery_od: "B3423",
		},
		{
			id: "Moses Mwangi",
			amount_paid: 1500,
			status: "incomplete",
			battery_id: "B55678",
			battery_od: "B3423",
		},
		{
			id: "Sofia Ndege",
			amount_paid: 6000,
			status: "complete",
			battery_id: "B99001",
			battery_od: "B3423",
		},
	];

	const columns = [
		{ field: "id", headerName: "NAME", flex: 1 },
		{
			field: "amount_paid",
			headerName: "AMMOUNT PAID",
			flex: 1,
			cellClassName: "name-column--cell",
		},
		{
			field: "status",
			headerName: "PAYMENT STATUS", // active inactive
			flex: 1,
			renderCell: ({ row: { status } }) => {
				return (
					<Box
						width="60%"
						// m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
						backgroundColor={
							status === "complete"
								? colors.greenAccent[600]
								: status === "complete"
								? colors.redAccent[500]
								: colors.greenAccent[700]
						}
						borderRadius="4px"
					>
						{status === "complete" && <DoneIcon />}
						{status === "incomplete" && <ClearIcon />}
						<Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
							{status}
						</Typography>
					</Box>
				);
			},
		},
		{
			field: "battery_id",
			headerName: "BATTERY IN",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
		},
		{
			field: "battery_od",
			headerName: "BATTERY OUT",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
		},
		{
			field: "view",
			headerName: "VIEW CUSTOMER DETAILS",
			flex: 1,
			renderCell: ({ row: { id } }) => {
				return (
					<Button
						variant="Primary"
						sx={{
							backgroundColor: colors.blueAccent[400],
							color: colors.blueAccent[900],
						}}
						onClick={() => {}}
					>
						View Details
					</Button>
				);
			},
		},
	];
	return (
			<Box
				m="20px 0"
				height="50vh"
				sx={{
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-cell": {
						borderBottom: "none",
					},
					"& .name-column--cell": {
						color: colors.greenAccent[300],
					},
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: colors.blueAccent[700],
						borderBottom: "none",
					},
					"& .MuiDataGrid-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none",
						backgroundColor: colors.blueAccent[700],
					},
					"& .MuiCheckbox-root": {
						color: `${colors.greenAccent[200]} !important`,
					},
				}}
			>
				<DataGrid rows={mockData} columns={columns} />
			</Box>
	);
};

export default CabinetCustomerList;
