import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { DataGrid } from "@mui/x-data-grid";

const BatteryHistory = () => {
	const theme = useTheme(); // grab theme from material UI context
	const colors = tokens(theme.palette.mode);

	// Mock data for the new table with battery_id and state_of_charge
	const mockData = [
		{
			id: "B12345",
			state_of_charge: "75%",
		},
		{
			id: "B67890",
			state_of_charge: "50%",
		},
		{
			id: "B11223",
			state_of_charge: "90%",
		},
		{
			id: "B33445",
			state_of_charge: "30%",
		},
		{
			id: "B55678",
			state_of_charge: "60%",
		},
		{
			id: "B99001",
			state_of_charge: "85%",
		},
	];

	// Columns configuration for the new table
	const columns = [
		{
			field: "id",
			headerName: "BATTERY ID",
			flex: 1,
		},
		{
			field: "state_of_charge",
			headerName: "STATE OF CHARGE",
			flex: 1,
			renderCell: ({ row: { state_of_charge } }) => (
				<Box
					width="100%"
					display="flex"
					justifyContent="center"
					backgroundColor={
						parseInt(state_of_charge) > 50
							? colors.greenAccent[600]
							: colors.redAccent[500]
					}
					borderRadius="4px"
					p="5px"
				>
					<Typography color={colors.grey[100]}>
						{state_of_charge}
					</Typography>
				</Box>
			),
			align: "center",
			headerAlign: "center",
		},
	];

	return (
		<Box
			m="20px 0"
			height="50vh"
			sx={{
				"& .MuiDataGrid-root": {
					border: "none",
				},
				"& .MuiDataGrid-cell": {
					borderBottom: "none",
				},
				"& .MuiDataGrid-columnHeaders": {
					backgroundColor: colors.blueAccent[700],
					borderBottom: "none",
				},
				"& .MuiDataGrid-virtualScroller": {
					backgroundColor: colors.primary[400],
				},
				"& .MuiDataGrid-footerContainer": {
					borderTop: "none",
					backgroundColor: colors.blueAccent[700],
				},
				"& .MuiCheckbox-root": {
					color: `${colors.greenAccent[200]} !important`,
				},
			}}
		>
			<DataGrid rows={mockData} columns={columns} />
		</Box>
	);
};

export default BatteryHistory;
