import React from 'react'
import { Box, Typography, useTheme, Button } from '@mui/material'
import { tokens } from '../../../theme'
import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import Header from '../../../components/Header';
import StatBox from '../../../components/StatBox';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PublicIcon from '@mui/icons-material/Public';
import TwoWheeler from "@mui/icons-material/TwoWheeler";
import { useNavigate } from 'react-router-dom';

const CabinetHomepage = () => {

  const navigate = useNavigate()
  const viewCabinetDetails = (cabinetid) =>{
    console.log(`operations/cabinets/${cabinetid}`)
    navigate(`${cabinetid}`)
  }

  const theme = useTheme() // grab  theme from material ui context, set in layout.js 
  const colors = tokens(theme.palette.mode)

  const mockData  = [
    {
      display_name: 'Lexo Station',
      id: '866557054306511',
      sim_card_number: '0791153857',
      installed_batteries: 6,
      revenue: 7,
      swaps_today:8,
      bluetooth_mac: null,
      latitude: -1.299630,
      longitude: 36.795050,
      provider_id:'866557054306511',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Karen (Kabwagi)',
      id: '866557054305885',
      sim_card_number: '0706629758',
      installed_batteries: 7,
      revenue: 8,
      swaps_today:8,
      bluetooth_mac: null,
      latitude: -1.317593,
      longitude: 36.718051,
      provider_id:'866557054305885',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'West View Station',
      id: '866557054044906',
      sim_card_number: '0706635319',
      installed_batteries: 5,
      revenue: 7,
      swaps_today:8,
      bluetooth_mac: null,
      latitude: -1.263123,
      longitude: 36.802511,
      provider_id:'866557054044906',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Mountain View Station',
      id: '866557054271749',
      sim_card_number: '0706228868',
      installed_batteries: 2,
      revenue: 53454,
      swaps_today:8,
      bluetooth_mac: '0C:DC:7E:18:19:CA',
      latitude: -1.265385,
      longitude: 36.740930,
      provider_id:'866557054271749',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Limuru Rd',
      id: '866557054273281',
      sim_card_number: '0748579077',
      installed_batteries: 3,
      revenue: 53454,
      swaps_today:8,
      bluetooth_mac: null,
      latitude: -1.257927,
      longitude: 36.827216,
      provider_id:'866557054273281',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Lusaka Rd',
      id: '866557054306081',
      sim_card_number: '0706639129',
      installed_batteries: 4,
      revenue: 53454,
      swaps_today:8,
      bluetooth_mac: '0C:DC:7E:17:AA:CA',
      latitude: -1.304486,
      longitude: 36.828083,
      provider_id:'866557054306081',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Langata',
      id: '866192037681580',
      sim_card_number: '0798487160',
      installed_batteries: 5,
      revenue: 6,
      swaps_today:8,
      bluetooth_mac: 'CC:DB:A7:AC:D9:8E',
      latitude: -1.322788,
      longitude: 36.801820,
      provider_id:'866192037681580',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Bomas',
      id: '866557054300878',
      sim_card_number: '0748579046',
      installed_batteries: 2,
      revenue: 53454,
      swaps_today:8,
      bluetooth_mac: 'CC:DB:A7:AC:D9:92',
      latitude: -1.341343,
      longitude: 36.753610,
      provider_id:'866557054300878',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Pangani',
      id: '869556069199348',
      sim_card_number: '0706626629',
      installed_batteries: 5,
      revenue: 6,
      swaps_today:8,
      bluetooth_mac: null,
      latitude: -1.272464,
      longitude: 36.835544,
      provider_id:'869556069199348',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Haile Selassie',
      id: '866557054083276',
      sim_card_number: '0748579104',
      installed_batteries: 5,
      revenue: 8,
      swaps_today:8,
      bluetooth_mac: 'CC:DB:A7:AC:D9:62',
      latitude: -1.293142,
      longitude: 36.821586,
      provider_id:'866557054083276',
      energy_tariff:["daytariff", "nighttariff"],
      status:"inactive"
    },
    {
      display_name: 'Ruiru Station',
      id: '866557054152709',
      sim_card_number: '0115461180',
      installed_batteries: 4,
      revenue: 53454,
      swaps_today:8,
      bluetooth_mac: '0C:DC:7E:17:AA:A2',
      latitude: -1.152172,
      longitude: 36.961895,
      provider_id:'866557054152709',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Ngong Town',
      id: '869640058220404',
      sim_card_number: '0740813778',
      installed_batteries: 3,
      revenue: 53454,
      swaps_today:8,
      bluetooth_mac: '34:86:5D:71:20:92',
      latitude: -1.358870,
      longitude: 36.656750,
      provider_id:'869640058220404',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    },
    {
      display_name: 'Enterprise',
      id: '866557054300654',
      sim_card_number: '0115460865',
      installed_batteries: 4,
      revenue: 53454,
      swaps_today:8,
      bluetooth_mac: '0C:DC:7E:17:FB:F2',
      latitude: -1.321808,
      longitude: 36.870019,
      provider_id:'866557054300654',
      energy_tariff:["daytariff", "nighttariff"],
      status:"active"
    }
  ]


  const columns = [
    { field: "id", headerName: "ID", flex: 1, },
    {
      field: "display_name",
      headerName: "LOCATION",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "status",
      headerName: "CABINET STATUS", // active inactive
      flex: 1,
      renderCell: ({ row: { status } }) => {
        return (
          <Box
            width="60%"
            // m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              status === "active"
                ? colors.greenAccent[600]
                : status === "inactive"
                ? colors.redAccent[500]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {status === "active" && <DoneIcon />}
            {status === "inactive" && <ClearIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {status}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "swaps_today",
      headerName: "SWAPS TODAY",
      flex: 1,
      cellClassName: "name-column--cell",
      align:"center",
      headerAlign: "center",
    },
    {
      field: "revenue",
      headerName: "TODAY REVENUE",
      flex: 1,
      cellClassName: "name-column--cell",
      align:"center",
      headerAlign: "center",
    },
    {
      field: "view",
      headerName: "VIEW DETAILS",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
            }}
            onClick={()=> viewCabinetDetails(id)}
          >
              View Details
          </Button>
        );
      },
    },
  ];
  return (
    <Box m="20px">
    <Header title="Cabinets" subtitle="Powerhive Cabinets" />
    <Box 
      display={'grid'}
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="140px"
      gap="20px"
    >
      {/* first row */}
      <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="200"
            subtitle="Swaps completed today"
            progress="0.75"
            increase="+14%"
            icon={
              <TwoWheeler
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="431,225"
            subtitle="Energy used Today"
            progress="0.50"
            increase="+21%"
            icon={
              <PublicIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Cummulative revenue today"
            progress="0.80"
            increase="+43%"
            icon={
              <AttachMoneyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
    </Box>
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
      }}
    >
      <DataGrid rows={mockData} columns={columns} />
    </Box>
  </Box>
  )
}

export default CabinetHomepage