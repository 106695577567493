import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Typography, useTheme } from "@mui/material";
import Header from '../../../components/Header';
import BatteryBox from '../../../components/BatteryBox';
import { tokens } from '../../../theme';
import Energyconsumptionchart from '../../../components/cabinetCharts/EnergyConsumption';
import Revenuechart from '../../../components/cabinetCharts/RevenueGenerated';
import SwapsChart from '../../../components/cabinetCharts/SwapsCompleted';
import CabinetCustomerList from './CabinetCustomerList';
import BatteryHistory from './BatteryHistory';

const CabinetDetails = () => {
    
    const {cabinetid} = useParams()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
    <Box m={'20px'}>
      <Header 
        title={'Lexo station cabinet'}
        subtitle={'Cabinet id : ' + cabinetid}
      />
      <Box
        display={"grid"}
        gridTemplateColumns={"repeat(12, 1fr)"}
        gridAutoRows="140px"
        gap="20px"
        mb={"20px"}
      >
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BatteryBox 
            batteryId={"DSD54654654"}
            chargingprogress={0.4}
            temperature={42}
            cycles={522}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BatteryBox 
            batteryId={"DSD54654654"}
            chargingprogress={0.6}
            temperature={42}
            cycles={522}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BatteryBox 
            batteryId={"DSD54654654"}
            chargingprogress={0.8}
            temperature={42}
            cycles={522}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BatteryBox 
            batteryId={"DSD54654654"}
            chargingprogress={1}
            temperature={42}
            cycles={522}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BatteryBox 
            batteryId={"DSD54654654"}
            chargingprogress={0.3}
            temperature={42}
            cycles={522}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BatteryBox 
            batteryId={"DSD54654654"}
            chargingprogress={0.5}
            temperature={42}
            cycles={522}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BatteryBox 
            batteryId={"DSD54654654"}
            chargingprogress={0.2}
            temperature={42}
            cycles={522}
          />
        </Box>
      </Box>
      <Header 
        title={'Charts'}
      />
      <Box
          display={"grid"}
          gridTemplateColumns={"repeat(12, 1fr)"}
          gridAutoRows="140px"
          gap="20px"
          mt={"20px"}
          mb={"20px"}
      >
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Energy Consumption
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                34553 kWh
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <Energyconsumptionchart />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenue generated today
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                233434 ksh
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <Revenuechart />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Swaps completed today
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                420 Swaps
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <SwapsChart />
          </Box>
        </Box>
      </Box>
      <Header 
        title={'Customers Served by Cabinet'}
      />
      <Box
        display={'flex'}
        // justifyContent={'center'}
        alignItems={'center'}
        gap={"20px"}
      >
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Today
        </Button>
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Yesterday
        </Button>
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Enter custom date range
        </Button>
      </Box>
      <CabinetCustomerList />


      <Header 
        title={'Cabinet Battery history'}
        subtitle={"Batteries in cabinet one hour ago"}
      />
      <Box
        display={'flex'}
        // justifyContent={'center'}
        alignItems={'center'}
        gap={"20px"}
      >
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Today
        </Button>
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Yesterday
        </Button>
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Enter custom date range
        </Button>
      </Box>
      <BatteryHistory />
    </Box>
  )
}

export default CabinetDetails